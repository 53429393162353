import React, { useEffect, useState } from 'react';
import { shuffle } from 'lodash';
import ScrollbarVertical from '../../components/project-ui/ScrollbarVertical';
import { PageLeaderboardTeacherDownload } from '../../pages/leaderboard';
import { getRanking } from '../../utils/api';
import LeaderboardUser from '../../utils/models/LeaderboardUser';
import { useAuthContext } from '../../utils/providers/AuthProvider';
import "./ModalLeaderBoardV4.scss";
import "../../pages/dialog-teacher.scss";
import { AvatorIcons } from './ModalProfileAvatorSelectorV4';
import ModalReel from './ModalReel';
import { RootPortal } from '../../utils/providers/AppConfigProvider';
import BgRowAbs from "./BgRowAbs";
import { extendsLeaderboardUser, filterByLevel, LeaderboardUserEx, LEVELS } from '../constants';
interface ModalLeaderBoardV4Props {
  onClose?: () => void,
}

const LeaderboardUserItem = ({ user, index }: { user: LeaderboardUser, index: number }) => {
  return (
    <div className="modal-leader-board-student">
      <div className="modal-leader-board-student-index">{1 + index}</div>
      <div className="modal-leader-board-student-avator" data-uc={user.user_character}>
        {/* {null !== user.user_character && !isNaN(parseInt(user.user_character)) && AvatorIcons[parseInt(user.user_character)] && <img src={AvatorIcons[parseInt(user.user_character)]} alt={user.cname} />} */}
        {<img src={AvatorIcons[Math.round(1 + Math.random() * 30)]} alt={user.cname} />}
      </div>
      <div className="modal-leader-board-student-names">
        <span className="modal-leader-board-student-name">{user.cname} </span>
        <span className="modal-leader-board-student-seperator">-</span>
        <span className="modal-leader-board-student-school"> - {user.cSchoolName}</span>
        <span className="modal-leader-board-student-class-no"> ({user.classNo})</span>
      </div>
      {/* <div className="modal-leader-board-student-star"></div> */}
      {/* <div className="modal-leader-board-student-score">{parseInt(user.sum_star)} / {parseInt(user.sum_score)}</div> */}
      <div className="modal-leader-board-student-score">{parseInt(user.sum_score)}</div>
    </div>
  )
}

const ScrollList = ({ type }: { type: 'all' | 'school' }) => {
  const [dataAll, setDataAll] = useState<LeaderboardUserEx[]>([]);
  const [data, setData] = useState<LeaderboardUserEx[]>([]);
  const [loaded, setLoaded] = useState(false);
  const { logged } = useAuthContext();
  const [level, setLevel] = useState<1 | 2 | 3 | 4>(1);

  useEffect(() => {
    setLoaded(false);
    setDataAll([]);
    setData([]);
    setLevel(1);
    getRanking(type).then(r => {
      let temp = extendsLeaderboardUser(r);
      setDataAll(temp);
      setData(filterByLevel(temp, 1));
      setLoaded(true);
    });
  }, [type]);

  useEffect(() => {
    let temp = filterByLevel(dataAll, level);
    if (1 === level) {
      let chunks: { [score: number]: Array<LeaderboardUserEx> } = {};
      temp.forEach(x => {
        chunks[x.sumScore] = chunks[x.sumScore] ? chunks[x.sumScore] : [];
        chunks[x.sumScore].push(x);
      });
      let scores = Object.keys(chunks).sort((a, b) => b.localeCompare(a));
      let temp2: Array<LeaderboardUserEx> = [];
      scores.forEach(score => {
        temp2 = temp2.concat(shuffle(chunks[parseInt(score)]));
      })

      temp = temp2;
    } else {
      temp = shuffle(temp);
    }
    setData(temp);
  }, [dataAll, level]);

  const handleChangeLevel = (i: 1 | 2 | 3 | 4) => {
    setLevel(i);
  }

  if (!loaded) {
    return <>加載中...</>;
  }
  // console.log(logged);
  return (<ScrollbarVertical>
    <div className="modal-leader-board-list-levels">
      {LEVELS.map((LEVEL, index) => (
        <BgRowAbs onClick={() => handleChangeLevel((1 + index) as 1 | 2 | 3 | 4)} className={`${level === 1 + index ? 'active ' : ''}modal-leader-board-list-level-${1 + index} modal-leader-board-list-level`}>
          <div className="decoration"></div>
          {LEVEL.name ? LEVEL.name.split('').map((s, i) => <div className="char" key={i}>{s}</div>) : null}
          <div className="decoration"></div>
        </BgRowAbs>
      ))}
    </div>
    {0 === data.length ? <>沒有資料</> : <div>
      {data.map((leaderboardUser, index) => <LeaderboardUserItem key={leaderboardUser.userID} user={leaderboardUser} index={index} />)}
    </div>}
    <RootPortal>
      {logged && 'Teacher' === logged.role ? <PageLeaderboardTeacherDownload data={data} /> : null}
    </RootPortal>
  </ScrollbarVertical>)
}

const ModalLeaderBoardV4 = ({ onClose }: ModalLeaderBoardV4Props) => {
  const [type, setType] = useState<'all' | 'school'>('school');

  return (
    <ModalReel className="modal-leader-board" open={true} onClose={onClose}>
      <div className="modal-leader-board-list">
        <ScrollList type={type} />
      </div>
      <div className="modal-leader-board-kaka-1"></div>
      <div className="modal-title-row">
        <div className="title">排行榜</div>
        <div className="modal-title" onClick={() => setType('all')}>
          <div className="modal-title-l"></div>
          <div className="modal-title-c">全港</div>
          <div className="modal-title-r"></div>
        </div>
        <div className="modal-title modal-title-school" onClick={() => setType('school')}>
          <div className="modal-title-l"></div>
          <div className="modal-title-c">校內</div>
          <div className="modal-title-r"></div>
        </div>
      </div>
    </ModalReel>)
}

export default ModalLeaderBoardV4;
